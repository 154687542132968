import React from "react";
import "./Education.css";
import EducationCard from "../../components/researchCard/EducationCard";
import { educationInfo } from "../../portfolio";
// import {researchinfo} from "../../portfolio";
import { researchinfo } from "../../portfolio";
import Button from "../../components/button/Button";



export default function Education() {
    if (educationInfo.display) {
        return ( <
            div className = "education-section"
            id = "research" >
            <
            h1 className = "education-heading" > Research < /h1>

            <
            div className = "education-card-container" > {
                researchinfo.schools.map(school => ( <
                    EducationCard school = { school }
                    />
                ))
            }

            <
            /div>

            <
            /div>
        );
    }
    return null;
}